/**
 * NOTE: This requires `@sentry/nextjs` version 7.3.0 or higher.
 *
 * This page is loaded by Nextjs:
 *  - on the server, when data-fetching methods throw or reject
 *  - on the client, when `getInitialProps` throws or rejects
 *  - on the client, when a React lifecycle method throws or rejects, and it's
 *    caught by the built-in Nextjs error boundary
 *
 * See:
 *  - https://nextjs.org/docs/basic-features/data-fetching/overview
 *  - https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 *  - https://reactjs.org/docs/error-boundaries.html
 */

import * as Sentry from '@sentry/nextjs';
import NextErrorComponent from 'next/error';

const CustomErrorComponent = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    // If getInitialProps hasn't run, capture the error
    Sentry.captureException(err);
  }

  return <NextErrorComponent statusCode={statusCode} />;
};

CustomErrorComponent.getInitialProps = async (contextData) => {
  const { res, err } = contextData;

  const errorInitialProps = await NextErrorComponent.getInitialProps(contextData);
  errorInitialProps.hasGetInitialPropsRun = true; // Mark when getInitialProps has run

  if (err) {
    Sentry.captureException(err);
    await Sentry.flush(2000); // Ensure Sentry has time to send the error

    return errorInitialProps;
  }

  if (res?.statusCode === 404) {
    return { statusCode: 404 };
  }

  return errorInitialProps;
};

export default CustomErrorComponent;